import { type PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useAuthContextQuery } from '../../../@types/graphql'
import LoadingOverlay from '../../../components/LoadingOverlay'
import isFirstLoading from '../../services/apollo/utils/isFirstLoading'
import { AuthContext } from './context'
import {auth} from "../../services/auth";

export default function AuthProvider(props: PropsWithChildren) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined)

  const { data, networkStatus } = useAuthContextQuery({
    skip: !isAuthenticated,
    onError: () => {
      auth.signOut()
    },
  })

  const user = useMemo(() => data?.getAuthenticatedUser, [data])

  useEffect(() => {
    auth.onAuthStateChanged((token) => {
      setIsAuthenticated(!!token)
    })
  }, [])

  const isLoading = useMemo(
    () => isFirstLoading(networkStatus) || isAuthenticated === undefined,
    [networkStatus, isAuthenticated]
  )

  return (
    <>
      <AuthContext.Provider
        value={{
          isAuthenticated,
          isLoading,
          user,
        }}
      >
        {isLoading ? <LoadingOverlay /> : props.children}
      </AuthContext.Provider>
    </>
  )
}
