import { type PropsWithChildren } from 'react'
import { Container, Flex, ScaleFade } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

export default function PublicLayout({ children }: PropsWithChildren) {
  const { pathname } = useLocation()

  return (
    <Flex minH="100dvh" bgSize="cover" align={{ base: 'start', md: 'center' }} justify="center">
      <Container px={{ base: 4, md: 8, lg: 12 }} py={{ base: 4, md: 8 }} variant="main">
        <ScaleFade key={pathname} initialScale={0.98} in={true} style={{ minHeight: '100%' }}>
          {children}
        </ScaleFade>
      </Container>
    </Flex>
  )
}
