import { type PropsWithChildren, Suspense, useEffect, useMemo } from 'react'
import { Navigate, useLocation, useNavigate, useRoutes } from 'react-router-dom'
import routes from '~react-pages'
import DashboardLayout from '../components/layouts/dashboard'
import LoadingOverlay from '../components/LoadingOverlay'
import { AuthLayout } from '../components/layouts/auth'
import { useAuthContext } from '../core/context/auth/context'
import PublicLayout from '../components/layouts/public'
import { AUTH_ROUTES, PUBLIC_ROUTES, ROUTES } from '.'

export default function Pages() {
  const navigate = useNavigate()
  const page = useRoutes(routes)

  useEffect(() => {
    if (!page?.props.children) {
      navigate(ROUTES.ERROR_404, { replace: true })
    }
  }, [page])

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <GuardRoute>
        <LayoutRoute>{page}</LayoutRoute>
      </GuardRoute>
    </Suspense>
  )
}

const LayoutRoute = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation()
  const isPublicRoute = useMemo(() => PUBLIC_ROUTES.includes(pathname), [pathname])
  const isAuthRoute = useMemo(() => AUTH_ROUTES.includes(pathname), [pathname])

  if (isPublicRoute) return <PublicLayout>{children}</PublicLayout>
  if (isAuthRoute) return <AuthLayout>{children}</AuthLayout>
  return <DashboardLayout>{children}</DashboardLayout>
}

const GuardRoute = (props: PropsWithChildren) => {
  const { isAuthenticated } = useAuthContext()

  const { pathname } = useLocation()
  const isPublicRoute = useMemo(() => PUBLIC_ROUTES.includes(pathname), [pathname])
  const isAuthRoute = useMemo(() => AUTH_ROUTES.includes(pathname), [pathname])

  if (!isPublicRoute) {
    if (isAuthRoute && isAuthenticated) return <Navigate to={ROUTES.HOME} />
    if (!isAuthRoute && !isAuthenticated) return <Navigate to={ROUTES.SIGNIN} />
  }

  return <>{props.children}</>
}
