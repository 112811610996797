import * as Sentry from '@sentry/react'
import config from '../../../config'

Sentry.init({
  dsn: 'https://0445aa7bfa9a4642bd4c1e02e41c2f98@o4505306876280832.ingest.sentry.io/4505307119616000',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [config.api.endpoint, config.api.graphqlEndpoint],
    }),
    new Sentry.Replay(),
  ],
  environment: config.env,
  enabled: config.isProduction,
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
})
