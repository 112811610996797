import { Image, type ImageProps } from '@chakra-ui/react'

type Props = ImageProps & {
  short?: boolean
}

export const Logo = ({ short = false, ...props }: Props) => {
  return (
    <Image
      fit="contain"
      h="2rem"
      {...props}
      src={short ? '/images/short-logo.png' : '/images/logo.png'}
      alt="Logo"
    />
  )
}
