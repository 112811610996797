import {
  Box,
  CloseButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Show,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'
import { User, HamburgerButton } from '@icon-park/react'
import { DashboardMenu } from './Menu'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useScreenSize from '../../../core/hooks/useScreenSize'
import { Logo } from '../../Logo'
import { ROUTES } from '../../../routes'
import FidoLinkButton from '../../override/FidoLinkButton'
import SearchbarV2 from './Searchbar'

export const DashboardHeader = () => {
  const { pathname } = useLocation()
  const { isLargerThanMd } = useScreenSize()
  const [isLargerThan321] = useMediaQuery('(min-width: 321px)')

  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    onClose()
  }, [pathname, onClose])

  return (
    <Flex
      minH="70px"
      px={isLargerThanMd ? 4 : 2}
      align="center"
      bg="white"
      gap={1}
      borderBottom="1px solid var(--chakra-colors-gray-200)"
    >
      <Show below="md">
        <IconButton
          onClick={onOpen}
          size="lg"
          aria-label="Open Menu"
          icon={<HamburgerButton size={22} />}
          variant="ghost"
        />
        <Drawer isOpen={isOpen} onClose={onClose} placement="left">
          <DrawerOverlay />
          <DrawerContent>
            {!isLargerThan321 && (
              <Box position="absolute" right={0} p={2}>
                <CloseButton onClick={onClose} />
              </Box>
            )}
            <DashboardMenu />
          </DrawerContent>
        </Drawer>
        <FidoLinkButton
          size="lg"
          variant="ghost"
          fontWeight="normal"
          textDecoration="unset"
          to={ROUTES.DASHBOARD}
          px={2}
        >
          <Logo short minW="48px" w="48px" />
        </FidoLinkButton>
      </Show>
      <SearchbarV2 />
      <Box ml="auto">
        <UserMenu />
      </Box>
    </Flex>
  )
}

const UserMenu = () => {
  const navigate = useNavigate()

  const confirmLogout = () => {
    window.confirm('Tem certeza?') && navigate(ROUTES.SIGNOUT)
  }

  return (
    <Menu size="lg" strategy="fixed">
      <MenuButton as={IconButton} size="lg" aria-label="Options" icon={<User />} variant="ghost" />
      <MenuList>
        <MenuItem onClick={() => navigate(ROUTES.ME)}>Meus dados</MenuItem>
        {/* <Restrict allowed={['super']}>
          <MenuItem onClick={() => navigate(ROUTES.USERS_LIST)}>Gerenciar Usuários</MenuItem>
        </Restrict> */}
        <MenuDivider />
        <MenuItem onClick={confirmLogout} color="red.500">
          Sair
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
