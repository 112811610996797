import { createRoot } from 'react-dom/client'
import theme from './styles/theme'
import { ChakraProvider } from '@chakra-ui/react'
import AuthProvider from './core/context/auth/provider'
import { BrowserRouter } from 'react-router-dom'
import Apollo from './apollo'
import './styles/main.scss'
import Pages from './routes/Pages'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './components/ErrorFallback'

// Initialize Sentry
import './core/services/sentry'

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <ChakraProvider theme={theme}>
      <Apollo>
        <AuthProvider>
          <BrowserRouter>
            <Pages />
          </BrowserRouter>
        </AuthProvider>
      </Apollo>
    </ChakraProvider>
  </ErrorBoundary>
)
