import { useEffect } from 'react'
import { type FallbackProps } from 'react-error-boundary'

// https://mitchgavan.com/code-splitting-react-safely/

export default function ErrorFallback({ error }: FallbackProps) {
  // Handle failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/i
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000)
        window.location.reload()
      }
    }
  }, [error])

  return (
    <>
      <div>Algo de errado não está certo</div>
      <div>Reinicie a página</div>
    </>
  )
}

function setWithExpiry(key: string, value: string, ttl: number) {
  const item = {
    value,
    expiry: new Date().getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

function getWithExpiry(key: string) {
  const itemString = window.localStorage.getItem(key)
  if (!itemString) return null

  const item = JSON.parse(itemString)
  const isExpired = new Date().getTime() > item.expiry

  if (isExpired) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}
