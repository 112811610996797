import { type PropsWithChildren } from 'react'
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, Observable } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { ROUTES } from './routes'
import config from './config'
import { type FidoError } from './@types/error'
import { useToast } from './core/hooks/useToast'
import { auth } from './core/services/auth'

export default function Apollo({ children }: PropsWithChildren) {
  const toast = useToast()

  return (
    <ApolloProvider
      client={
        new ApolloClient({
          link: ApolloLink.from([
            onError(({ graphQLErrors, networkError }) => {
              console.error('graphQLErrors', JSON.stringify(graphQLErrors, null, 2))
              if (graphQLErrors) {
                graphQLErrors.forEach((err) => {
                  if (err.extensions?.code === 'FORBIDDEN') return window.location.replace(ROUTES.ERROR_403)
                  // if (err.extensions?.code === 'UNAUTHORIZED') return window.location.replace(ROUTES.SIGNOUT)

                  const { code, message } = err.extensions as FidoError

                  toast({
                    status: 'error',
                    title: errorToastMap[code].title,
                    description: errorToastMap[code].displayMessage ? message : undefined,
                  })
                })
              }
              if (networkError) {
                console.error('networkError', JSON.stringify(networkError, null, 2))
              }
            }),
            requestLink,
            httpLink,
          ]),
          cache: new InMemoryCache(),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'cache-and-network',
              errorPolicy: 'ignore',
            },
            query: {
              fetchPolicy: 'cache-first',
              errorPolicy: 'all',
            },
          },
        })
      }
    >
      {children}
    </ApolloProvider>
  )
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: any
      Promise.resolve(operation)
        .then(async (operation) => {
          const headers = {
            'x-api-type': config.api.type,
            'x-api-tenant': config.api.tenantKey,
          }

          const tokenResult = await auth.getCurrentUserIdToken()
          const token = tokenResult?.token
          if (token) {
            Object.assign(headers, {
              authorization: `bearer ${token}`,
            })
          }

          operation.setContext({ headers })
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

const httpLink = new HttpLink({
  uri: config.api.graphqlEndpoint,
  credentials: 'include',
})

const errorToastMap = {
  BAD_GATEWAY: { title: 'Erro de rede', displayMessage: true },
  BAD_REQUEST: { title: 'Solicitação incorreta', displayMessage: true },
  CONFLICT: { title: 'Conflito', displayMessage: true },
  FORBIDDEN: { title: 'Sem permissão', displayMessage: false },
  INTERNAL_SERVER_ERROR: { title: 'Erro Interno', displayMessage: false },
  NOT_FOUND: { title: 'Não encontrado', displayMessage: true },
  UNAUTHORIZED: { title: 'Não autorizado', displayMessage: true },
  VALIDATION: { title: 'Erro de validação', displayMessage: false },
}
