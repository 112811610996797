import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
import components from './components'

const theme = extendTheme(
  {
    styles: {
      global: {
        description: {
          bg: 'white',
        },
      },
    },
    fonts: {
      heading: `'Comfortaa', sans-serif`,
    },
    colors: {
      'hostel-pink': {
        50: '#ffe3f3',
        100: '#ffc7e7',
        200: '#ff99d1',
        300: '#ff5ab0',
        400: '#ff2990',
        500: '#fd1472',
        600: '#dd004f',
        700: '#b60041',
        800: '#970439',
        900: '#72032c',
      },
      'hostel-green': {
        50: '#faffe4',
        100: '#f3ffc5',
        200: '#e6ff92',
        300: '#d3ff53',
        400: '#bcfb20',
        500: '#94d500',
        600: '#78b500',
        700: '#5b8902',
        800: '#496c08',
        900: '#3e5b0c',
      },
    },
    components,
  },
  withDefaultColorScheme({ colorScheme: 'hostel-pink' })
)

export default theme
