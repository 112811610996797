import { CardBody, Flex, Image, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'
import FidoCard from '../../override/FidoCard'

type Props = {
  children: ReactNode
}

export const AuthLayout = ({ children }: Props) => {
  return (
    <Flex
      minH="100dvh"
      bg="linear-gradient(148deg, rgba(182,0,65,1) 0%, rgba(253,20,114,1) 46%, rgba(148,213,0,1) 90%)"
      align="center"
      justify="center"
    >
      <FidoCard maxW="400px" w="100%" mx={4} my={{ base: 2, md: 8 }}>
        <CardBody py={8} px={{ base: 6, md: 8 }}>
          <Flex align="baseline">
            <Image
              src="/images/logo.png"
              alt="Logo"
              fit="contain"
              mb={6}
              h="3rem"
            />
            <Text ms={2} color="hostel-pink.500">
              Admin
            </Text>
          </Flex>
          {children}
        </CardBody>
      </FidoCard>
    </Flex>
  )
}
