import { Box, Button, Divider, Flex, Stack } from '@chakra-ui/react'
import { Logo } from '../../../components/Logo'
import {
  FaBath,
  FaBuilding,
  FaCalendarAlt,
  FaCamera,
  FaChalkboard,
  FaCogs,
  FaCookieBite,
  FaDog,
  FaFileAlt,
  FaPills,
  FaSearchDollar,
  FaUsers,
} from 'react-icons/fa'
import { NavLink as ReactRouterNavLink } from 'react-router-dom'
import Restrict from '../../Restrict'
import { ROUTES } from '../../../routes'
import FidoLinkButton from '../../override/FidoLinkButton'

export const DashboardMenu = () => {
  const links = [
    { label: 'Dashboard', to: ROUTES.DASHBOARD, icon: <FaChalkboard /> },
    { label: 'Diários', to: ROUTES.FEEDBACKS_DASHBOARD, icon: <FaFileAlt /> },
    { label: 'Alimentação', to: ROUTES.MEALS_DASHBOARD, icon: <FaCookieBite /> },
    { label: 'Remédios', to: ROUTES.MEDICINES_DASHBOARD, icon: <FaPills /> },
    { label: 'Fotos', to: ROUTES.MEDIAS_DASHBOARD, icon: <FaCamera /> },
    { label: 'Banhos', to: ROUTES.GROOMINGS_DASHBOARD, icon: <FaBath /> },
    { label: 'Calendário', to: ROUTES.CALENDAR, icon: <FaCalendarAlt /> },
    { label: 'Pag. Pendentes', to: ROUTES.PENDING_PAYMENTS, icon: <FaSearchDollar /> },
  ]

  const moreLinks = [
    { label: 'Clientes', to: ROUTES.CUSTOMERS_LIST, icon: <FaUsers /> },
    { label: 'Pets', to: ROUTES.PETS_LIST, icon: <FaDog /> },
  ]

  const adminLinks = [
    { label: 'Escritório', to: ROUTES.OFFICE, icon: <FaBuilding /> },
    { label: 'Configurações', to: ROUTES.SETTINGS, icon: <FaCogs /> },
  ]

  return (
    <Stack px={4} align="start">
      <Flex h="70px" align="center" justify="center">
        <FidoLinkButton size="lg" variant="ghost" fontWeight="normal" textDecoration="unset" to={ROUTES.DASHBOARD}>
          <Logo />
        </FidoLinkButton>
      </Flex>
      {links.map((linkProps) => (
        <MenuLink key={linkProps.to} {...linkProps} />
      ))}
      <Restrict allowed={['super', 'admin', 'monitor']}>
        <>
          <Divider />
          {moreLinks.map((linkProps) => (
            <MenuLink key={linkProps.to} {...linkProps} />
          ))}
        </>
      </Restrict>
      <Restrict allowed={['super', 'admin']}>
        <>
          <Divider />
          {adminLinks.map((linkProps) => (
            <MenuLink key={linkProps.to} {...linkProps} />
          ))}
        </>
      </Restrict>
    </Stack>
  )
}

type MenuLinkProps = {
  label: string
  to: string
  icon: JSX.Element
}

const MenuLink = (props: MenuLinkProps) => {
  return (
    <Button
      as={ReactRouterNavLink}
      to={props.to}
      end
      leftIcon={props.icon}
      px={4}
      size="lg"
      variant="ghost"
      colorScheme="gray"
      w="100%"
      justifyContent="start"
      fontWeight="normal"
      _activeLink={{ bg: 'gray.100' }}
      _hover={{ color: 'unset', bg: 'gray.100' }}
    >
      <Box ms={2}>{props.label}</Box>
    </Button>
  )
}
