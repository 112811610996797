const AuthKey = 'fido:auth:data'

class Auth {
  async signOut() {
    this.setState(null)
  }

  setTokenResult(token: string, expiresIn: string) {
    this.setState({ token, expiresIn })
  }

  onAuthStateChanged(cb: (val: unknown) => void) {
    this.cbs.push(cb)
    cb(this.data)
  }

  private setState(newVal: unknown) {
    localStorage.setItem(AuthKey, JSON.stringify(newVal))
    this.data = newVal
    this.notifyStateChanged()
  }

  private notifyStateChanged() {
    setTimeout(() => this.cbs.forEach((cb) => cb(this.data)), 0)
  }

  async getCurrentUserIdToken() {
    // ToDo: se token tiver expirada limpa local storage
    return this.data as { token: string }
  }

  constructor(
    private readonly cbs: ((val: unknown) => void)[] = [],
    private data: unknown = null
  ) {
    const jwt = localStorage.getItem(AuthKey)
    if (jwt) {
      this.data = JSON.parse(jwt)
    }
  }
}

export const auth = new Auth()

export class AuthError extends Error {
  constructor(
    message: string,
    public data: { code: number; message: string }
  ) {
    super(message)
  }
}
