import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import { FaSearch, FaUser } from 'react-icons/fa'
import useScreenSize from '../../../../core/hooks/useScreenSize'
import { useEffect, useState } from 'react'
import { useSearchbarQuery } from '../../../../@types/graphql'
import { useDebounce } from '../../../../core/hooks/useDebounce'
import FidoLink from '../../../override/FidoLink'
import { ROUTES } from '../../../../routes'
import FidoLinkButton from '../../../override/FidoLinkButton'
import { useLocation } from 'react-router-dom'

export default function SearchbarV2() {
  const location = useLocation()

  const { isLargerThanMd } = useScreenSize()
  const modal = useDisclosure({
    onClose() {
      clearText()
    },
  })

  const [text, setText] = useState('')
  const clearText = () => setText('')
  const debouncedText = useDebounce<string>(text, 400)
  const skip = debouncedText === '' || debouncedText.length < 3

  const { data, loading } = useSearchbarQuery({
    variables: {
      keyword: debouncedText,
      options: { pagination: { page: 0, pageSize: 10 }, sort: [{ id: 'name', desc: false }] },
    },
    skip,
    fetchPolicy: 'no-cache',
  })

  const [pets, customers] = [data?.listPets?.items ?? [], data?.listCustomers?.items ?? []]

  const displayResults = !skip && !loading

  useEffect(() => {
    modal.onClose()
  }, [location])

  return (
    <>
      <Button
        color="gray.500"
        display="inline"
        colorScheme="gray"
        px={3}
        py={2}
        borderRadius="md"
        cursor="pointer"
        maxW={!isLargerThanMd ? '100%' : '500px'}
        w="calc(100% - 1rem)"
        fontWeight="normal"
        onClick={modal.onOpen}
      >
        <Flex align="center" gap={3}>
          <FaSearch color="gray" />
          Procurar...
        </Flex>
      </Button>
      <Modal
        isOpen={modal.isOpen}
        onClose={modal.onClose}
        size={isLargerThanMd ? 'lg' : 'full'}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent overflow="hidden" p={0}>
          <ModalHeader>
            <InputGroup>
              <Input
                fontSize="2xl"
                autoFocus
                variant="unstyled"
                value={text}
                placeholder="Procurar..."
                onChange={(e) => setText(e.target.value)}
              />
              <InputRightElement w="min-content" me={5}>
                {text !== '' && (
                  <Button h="1.75rem" mr={2} size="sm" onClick={clearText} colorScheme="gray">
                    Limpar
                  </Button>
                )}
                {loading && <Spinner color="hostel-pink.500" size="sm" />}
              </InputRightElement>
            </InputGroup>
          </ModalHeader>
          <ModalCloseButton />
          {displayResults && (
            <>
              <Divider />
              <ModalBody p={0}>
                <Flex direction="column">
                  <Flex align="baseline" justify="space-between" px={4} py={2} bg="#fff6fb">
                    <Box fontSize="sm" color="gray.600" fontWeight="medium">
                      PETS
                    </Box>
                    <FidoLinkButton to={ROUTES.PETS_LIST} variant="link" size="sm">
                      Ver todos
                    </FidoLinkButton>
                  </Flex>
                  {pets && pets.length > 0 ? (
                    pets.map((pet) => (
                      <Flex
                        key={pet?.id}
                        as={FidoLink}
                        to={ROUTES.PETS_DETAIL(Number(pet?.id))}
                        onClick={clearText}
                        align="center"
                        variant="unstyled"
                        _hover={{
                          textDecoration: 'none',
                          color: 'unset',
                          bg: 'gray.100',
                        }}
                        px={4}
                        py={2}
                      >
                        <Avatar src={pet?.avatar ?? undefined} size="lg" me={3} />
                        <Flex direction="column">
                          <Flex gap={2} align="baseline">
                            <Box fontSize="xl" fontWeight="medium">
                              {pet?.name}
                            </Box>
                            <Box fontSize="xs" color="gray.500">
                              #{pet?.id}
                            </Box>
                          </Flex>
                          <Box color="gray.500">{pet?.breedName}</Box>
                          <Flex align="baseline" gap={2} mt={1}>
                            <FaUser size="0.75rem" />
                            <Box fontSize="sm">{pet?.customer?.name}</Box>
                          </Flex>
                        </Flex>
                      </Flex>
                    ))
                  ) : (
                    <Box px={4} py={2}>
                      Nenhum pet encontrado
                    </Box>
                  )}
                </Flex>
                <Flex direction="column">
                  <Flex align="baseline" justify="space-between" px={4} py={2} bg="#fff6fb">
                    <Box fontSize="sm" color="gray.600" fontWeight="medium">
                      CLIENTES
                    </Box>
                    <FidoLinkButton to={ROUTES.CUSTOMERS_LIST} variant="link" size="sm">
                      Ver todos
                    </FidoLinkButton>
                  </Flex>
                  {customers && customers.length > 0 ? (
                    customers?.map((customer) => (
                      <Flex
                        key={customer?.id}
                        as={FidoLink}
                        to={ROUTES.CUSTOMERS_DETAIL(customer?.id as unknown as number)}
                        onClick={clearText}
                        align="center"
                        variant="unstyled"
                        _hover={{
                          textDecoration: 'none',
                          color: 'unset',
                          bg: 'gray.100',
                        }}
                        px={4}
                        py={2}
                      >
                        <Box flexGrow={1}>
                          <Flex gap={2} align="baseline">
                            <Box fontSize="xl" fontWeight="medium">
                              {customer?.name}
                            </Box>
                            <Box fontSize="xs" color="gray.500">
                              #{customer?.id}
                            </Box>
                          </Flex>
                          {customer?.pets?.map((pet) => (
                            <Flex key={pet.id} py={1} align="center">
                              <Avatar src={pet?.avatar ?? undefined} h="2.25rem" w="2.25rem" me={3} />
                              <Flex direction="column">
                                <Box fontSize="md">{pet?.name}</Box>
                                <Box fontSize="xs" color="gray.500">
                                  {pet?.breedName}
                                </Box>
                              </Flex>
                            </Flex>
                          ))}
                        </Box>
                      </Flex>
                    ))
                  ) : (
                    <Box px={4} py={2} mb={1}>
                      Nenhum cliente encontrado
                    </Box>
                  )}
                </Flex>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
