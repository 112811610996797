import { Link, forwardRef, type ButtonProps, Button } from '@chakra-ui/react'
import { Link as ReactRouterLink, type LinkProps } from 'react-router-dom'

interface FidoLinkButtonProps extends ButtonProps {
  to: string
  isExternal?: boolean
  state?: LinkProps['state']
}

const FidoLinkButton = forwardRef<FidoLinkButtonProps, 'a'>(({ to, state, isExternal = false, ...props }, ref) => {
  const linkProps = isExternal
    ? {
        href: to,
      }
    : {
        as: ReactRouterLink,
        to,
      }

  return (
    <Link ref={ref} {...linkProps} isExternal={isExternal} state={state}>
      <Button {...props} />
    </Link>
  )
})

export default FidoLinkButton
