const env = process.env.NODE_ENV
const isProduction = process.env.NODE_ENV === 'production'

const config = {
  env,
  isProduction,
  api: {
    endpoint: import.meta.env.VITE_API_ENDPOINT,
    graphqlEndpoint: `${import.meta.env.VITE_API_ENDPOINT}/graphql`,
    type: 'employee',
    tenantKey: import.meta.env.VITE_API_TENANT_KEY,
  },
  s3BucketUrl: import.meta.env.VITE_S3_BUCKET_URL,
}

export default config
