import { useToast as useChakraToast } from '@chakra-ui/react'
import useScreenSize from './useScreenSize'

export const useToast = () => {
  const { isLargerThanSm } = useScreenSize()

  const toast = useChakraToast({
    position: isLargerThanSm ? 'bottom' : 'top',
    isClosable: true,
  })

  return toast
}
