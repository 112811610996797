import { type BookingType } from '../@types/graphql'

export const ROUTES = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  ME: '/me',
  FEEDBACKS_DASHBOARD: '/dashboard/feedbacks',
  MEDICINES_DASHBOARD: '/dashboard/medicines',
  MEALS_DASHBOARD: '/dashboard/meals',
  MEDIAS_DASHBOARD: '/dashboard/medias',
  GROOMINGS_DASHBOARD: '/dashboard/groomings',
  ERROR_403: '/error/404',
  ERROR_404: '/error/404',
  ERROR_500: '/error/500',
  SIGNIN: '/signin',
  SIGNOUT: '/signout',
  FORGOT_PASSWORD: '/password/forgot',
  CHANGE_PASSWORD: '/password/change',
  CUSTOMERS_LIST: '/customers',
  CUSTOMERS_DETAIL: (id: number) => `/customers/${id}`,
  PETS_LIST: '/pets',
  PETS_DETAIL: (id: number) => `/pets/${id}`,
  PETS_VACCINES: (id: number) => `/pets/${id}/health/vaccines`,
  PETS_STOOL_TESTS: (id: number) => `/pets/${id}/health/stool-tests`,
  PETS_TICK_MEDICINES: (id: number) => `/pets/${id}/health/tick-medications`,
  BOOKINGS_CREATE: (type: BookingType, customerId: number) => `/bookings/new?type=${type}&customerId=${customerId}`,
  BOOKINGS_EDIT: (id: number) => `/bookings/${id}/edit`,
  BOOKINGS_DETAIL: (id: number) => `/bookings/${id}`,
  BOOKING_STORAGES_CREATE: (bookingId: number, petId: number) => `/bookings/${bookingId}/storages/new?petId=${petId}`,
  BOOKING_STORAGES_EDIT: (bookingId: number, id: number) => `/bookings/${bookingId}/storages/${id}/edit`,
  BOOKING_MEDICINES_CREATE: (bookingId: number, petId: number) => `/bookings/${bookingId}/medicines/new?petId=${petId}`,
  BOOKING_MEDICINES_EDIT: (bookingId: number, id: number) => `/bookings/${bookingId}/medicines/${id}/edit`,
  CLUB_SUBSCRIPTION_DETAIL: (id: number) => `/club-subscriptions/${id}`,
  GROOMINGS_DETAIL: (id: number) => `/groomings/${id}`,
  SERVICE_ORDERS_DETAIL: (id: number) => `/service-orders/${id}`,
  ORDERS_LIST: '/orders',
  ORDERS_DETAIL: (id: string) => `/orders/${id}`,
  EMPLOYEES_LIST: '/employees',
  CALENDAR: '/calendar',
  PENDING_PAYMENTS: '/pending-payments',
  OFFICE: '/office',
  OFFICE_INVOICES: '/office/invoices',
  OFFICE_PETS_PET_DOCUMENTS: '/office/pets/pet-documents',
  OFFICE_PETS_HEALTH: '/office/pets/health',
  OFFICE_CLUB_SUBSCRIPTIONS_RENEW: '/office/club-subscriptions/renew',
  USERS_LIST: '/users',
  SETTINGS: '/settings',
  SETTINGS_ACCOUNTS: '/settings/accounts',
  SETTINGS_BOOKING_PRICES: '/settings/booking-prices',
  SETTINGS_CLUB_PLANS: '/settings/club-plans',
  SETTINGS_GROOMING_PRICES: '/settings/grooming-prices',
  SETTINGS_GROOMING_OPTIONS: '/settings/grooming-options',
  SETTINGS_GROOMING_OPTIONS_DETAIL: (id: string) => `/settings/grooming-options/${id}`,
}
export const PUBLIC_ROUTES = [ROUTES.SIGNOUT]
export const AUTH_ROUTES = [ROUTES.SIGNIN, ROUTES.FORGOT_PASSWORD, ROUTES.CHANGE_PASSWORD]
