import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  dialog: {
    borderRadius: '3xl',
    pb: 4,
    mx: 4,
  },
})

const full = defineStyle({
  mx: 0,
})

const sizes = {
  full: definePartsStyle({ dialog: full }),
}

export const modal = defineMultiStyleConfig({
  baseStyle,
  sizes,
})

export default modal
