import { Link, forwardRef, type LinkProps } from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'

interface FidoLinkProps extends LinkProps {
  to: string
  isExternal?: boolean
}

const FidoLink = forwardRef<FidoLinkProps, 'a'>(({ to, isExternal = false, ...props }, ref) => {
  const linkProps = isExternal
    ? {
        href: to,
      }
    : {
        as: ReactRouterLink,
        to,
      }

  return <Link ref={ref} {...props} {...linkProps} isExternal={isExternal} />
})

export default FidoLink
