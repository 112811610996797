import { type ComponentStyleConfig } from '@chakra-ui/react'
import card from './card'
import container from './container'
import drawer from './drawer'
import form from './form'
import modal from './modal'

const components: Record<string, ComponentStyleConfig> = {
  Form: form,
  Modal: modal,
  Card: card,
  Container: container,
  Drawer: drawer,
  Input: {
    variants: {
      filled: {
        background: 'white',
      },
    },
    defaultProps: {
      focusBorderColor: 'hostel-pink.100',
      variant: 'filled',
    },
  },
  NumberInput: {
    defaultProps: {
      focusBorderColor: 'hostel-pink.100',
      variant: 'filled',
    },
  },
  Select: {
    defaultProps: {
      focusBorderColor: 'hostel-pink.100',
      variant: 'filled',
    },
  },
  Link: {
    variants: {
      default: ({ colorScheme = 'hostel-pink' }) => ({
        color: `${colorScheme}.500`,
        _hover: {
          color: `${colorScheme}.700`,
          textDecoration: 'none',
        },
      }),
    },
    defaultProps: {
      variant: 'default',
    },
  },
  Textarea: {
    defaultProps: {
      focusBorderColor: 'hostel-pink.100',
      variant: 'filled',
    },
  },
  // to avoid bootstrap conflict
  Text: {
    baseStyle: {
      mb: 0,
    },
  },
  Divider: {
    baseStyle: {
      m: 0,
    },
  },
}

export default components
