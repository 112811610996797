import { type PropsWithChildren } from 'react'
import { Box, Flex, ScaleFade, Show } from '@chakra-ui/react'
import { DashboardHeader } from './Header'
import { useLocation } from 'react-router-dom'
import { DashboardMenu } from './Menu'

export default function DashboardLayout({ children }: PropsWithChildren) {
  const { pathname } = useLocation()

  return (
    <Flex h="100dvh">
      <Show above="md">
        <Box h="full" minW="16rem" bg="white" borderRight="1px solid var(--chakra-colors-gray-200)">
          <DashboardMenu />
        </Box>
      </Show>
      <Flex direction="column" overflowY={{ md: 'auto' }} w="100%">
        <Box position={{ base: 'fixed', md: 'sticky' }} top={0} w="100%" zIndex="docked">
          <DashboardHeader />
        </Box>
        <Box
          px={{ base: 4, md: 8, lg: 12 }}
          py={{ base: 4, md: 8 }}
          mt={{ base: '70px', md: 0 }}
          flexGrow={1}
          bg="gray.50"
        >
          <ScaleFade key={pathname} initialScale={0.98} in={true}>
            {children}
          </ScaleFade>
        </Box>
      </Flex>
    </Flex>
  )
}
