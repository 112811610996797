import { createContext, useContext } from 'react'
import { type AuthContextQuery } from '../../../@types/graphql'

export const useAuthContext = () => useContext(AuthContext)
export const AuthContext = createContext(
  {} as {
    isAuthenticated: boolean | undefined
    isLoading: boolean
    user: AuthContextQuery['getAuthenticatedUser'] | null
  }
)
