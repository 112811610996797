import { useMediaQuery } from '@chakra-ui/react'

export default function useScreenSize() {
  const [isLargerThanSm, isLargerThanMd, isLargerThanLg] = useMediaQuery([
    '(min-width: 30em)',
    '(min-width: 48em)',
    '(min-width: 62em)',
  ])

  return { isLargerThanSm, isLargerThanMd, isLargerThanLg }
}
