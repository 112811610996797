import { Box, Flex, Spinner } from '@chakra-ui/react'
import { Logo } from './Logo'

export default function LoadingOverlay() {
  return (
    <Flex h="100dvh" direction="column" align="center" justify="center" gap={6}>
      <Box width="150px">
        <Logo h="unset" />
      </Box>
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="hostel-pink.500" size="lg" />
    </Flex>
  )
}
