import React from 'react'
import { useAuthContext } from '../core/context/auth/context'

interface IOwnProps {
  allowed: string[]
  children: React.ReactNode
}

const Restrict = ({ allowed, children }: IOwnProps) => {
  const { user } = useAuthContext()

  if (!user?.role) return <></>
  if (!allowed.includes(user.role)) return <></>

  return <>{children}</>
}

export default Restrict
